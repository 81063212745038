


.container{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 55rem;
 

    img{
        border: 5px solid white;
        border-radius: 3px;
        object-fit: contain;
        margin: 0;


    }
    border-radius: 1rem;
    background-color: #D4D6D7;
    padding: 1rem;  
    margin-bottom: 1rem;

    a{
        font-size: 14px;
        padding: .5rem;
        // background-color:#347CC3;
        background-color:#F37402   ;
        border-radius: 2px;
        border: none;
        color: white;
        font-weight: 600;
        text-align: center;
        float: right;
        text-decoration:none
      
        }
        p{
            font-family: 'Poppins', sans-serif;
    
            color: black;
            font-size: 14px;
            font-weight: 500;
    text-align: justify
    
        };
}

.productServi
{

   
    margin-left: auto;
    margin-right: auto;
    text-align: start;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    border-radius: 3px;
color: black;
padding-right: .5rem;
padding-left: .5rem;




    h2{
        
        color:#066AA3;
        border-bottom: 1px solid gray;
        font-family: Verdana, Arial, Helvetica, sans-serif;

      }

    }




    .listas{
        display: flex;
        flex-direction: row;
      
 

            p{
                font-family: 'Poppins', sans-serif;
        
                color: black;
                font-size: 14px;
                font-weight: 500;
    

            };

            
   
           
    }

.li{

   

    display: flex;
    flex-direction: column;
    align-items: center;  
    background-color: #C0C2C3;
    position: relative;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem;
    gap: 10px;
    width: 15rem;
    margin-left: auto;
    margin-right: auto;
    p{
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;

        margin: 0;    
            text-align: start


    }
  

   
}
   
.titulo{
 
        // font-size: 14px;
        // font-weight: 600;
        // text-align:start;
        // padding: 1rem;
        // color: white;
        // background-color: #066AA3;
        // border-radius:3px;
        //     display: flex ;  
        //     margin-left: 1rem;    
        //     margin-right: 1rem;  
            .analisisP{
                font-size: 14px;
                background-color: #066AA3;
                color: white;
                border-radius:3px;    
                 padding: 1rem 3.7rem;
                font-weight: 600;
                text-align: start;
                display: flex;
            }
            .analisisD{
                font-size: 14px;
                background-color: #066AA3;
                color: white;
                border-radius:3px;    
                 padding: 1rem 3.7rem;
                font-weight: 600;
                text-align: justify;
                display: flex;
            }
            .maquina{
                font-size: 14px;
                background-color: #066AA3;
                color: white;
                border-radius:3px;    
                 padding: .5rem 2rem;
                font-weight: 600;
                display: flex;
                white-space:normal ;
            }
    
}




@media(width<1000px)
{
    .container{
        width: auto;
        margin: 1rem;
        font-size: 12px;

    }

    img{
        width: 120px;
    }
    .li{
        width: 180px;
        
    }


.titulo{
 
 padding: 0;
        .analisisP{
            font-size: 13px;
   
             padding: 1.5rem 1rem;
   
        }
        .analisisD{
            font-size: 13px;
   
            padding: 1.5rem 1rem;
   
        }
        .maquina{
            font-size: 13px;
    
             padding: 1rem 1rem;
   
            white-space:normal ;
        }

}

}