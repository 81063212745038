.container
{
    border-radius: 2px;
    padding:1rem ;

    margin-bottom:1rem ;
    display: flex;
    flex-direction: column;

    h2{
        // color:#f37402 ;
        font-family: Verdana, Arial, Helvetica, sans-serif;

        border-bottom: 2px solid white;
      }
    width: 40rem;
    color: white;
    font-weight: 600;

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: start;


p
{
    font-family: 'Poppins', sans-serif;
    
    color: white;
font-size: 14px;
font-weight: 400;
}

a{
    font-size: 14px;
    padding: .5rem;
    // background-color:#347CC3;
    background-color:#F37402   ;
    border-radius: 2px;
    border: none;
    color: white;
    font-weight: 600;
    text-align: center;
    text-decoration:none
  
    }
    img{
        border: 5px solid white;
        border-radius: 3px;
        object-fit: contain;
      

    }

}

.home{

    
    
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin: 0;
        p{
            font-family: 'Poppins', sans-serif;
            
            color: white;
        font-size: 14px;
        font-weight: 400;
        }    button{
            margin-top: 30px;
            font-size: 14px;
            padding: .4rem;
            // background-color:#347CC3;
            background-color:#F37402   ;
            border-radius: 2px;
            border: none;
            color: white;
            font-weight: 600;
            text-align: center;
          
            }
           button:hover{
            background-color:#ff9a42   ;

           }
    
    }
    






@media(width>1300px) {

    .home {

        font-size: 17px;
        margin-top: 6rem    ;

        p
        {
            font-family: 'Poppins', sans-serif;
            
            color: white;
            font-size: 14px;
            font-weight: 400;
        text-align: start;
        }
        img {
            width: 300px;
            object-fit: contain;
            height: 140px;
        }
    }

}



