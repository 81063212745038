.container{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 55rem;
    gap:20px;

    img{
        border: 5px solid white;
        border-radius: 3px;
        object-fit: contain;
        margin: 0;


    }
    border-radius: 1rem;
    background-color: #D4D6D7;
    padding: 1rem;  
    margin-bottom: 1rem;
    p{
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        gap: 10px;
    };



  a {
    margin-top: 1rem;
    font-size: 13px;
    padding: .5rem;
    background-color: #F37402;
    border-radius: 2px;
    border: none;
    color: white;
    font-weight: 600;
    text-align: center;
    float: right;
    text-decoration: none
}
.subtitulo{
    
    //   

        color: #066AA3;
        font-weight: 700;
     
            margin-top: 0;
        
}


}
.cloudpos
{

   
    margin-left: auto;
    margin-right: auto;
    text-align: start;
    display: flex;
    flex-direction: column;
   
    padding-bottom: 1rem;    border-radius: 3px;
color: black;
  
padding-right: .5rem;
padding-left: .5rem;



line-height : 25px;



       h2{
        
        color:#066AA3;
        border-bottom: 1px solid gray;
        font-family: Verdana, Arial, Helvetica, sans-serif;

      }

    }


    p{
        font-family: 'Poppins', sans-serif;

        color: black;
        font-size: 14px;
        font-weight: 500;


    };

    .listas{
        display: flex;
        flex-direction: row;
      
 

            p{
                font-family: 'Poppins', sans-serif;
        
                color: black;
                font-size: 14px;
                font-weight: 500;
    

            };

            
   
           
    }

.li{

   

    display: flex;
    flex-direction: column;
    align-items: center;  
    background-color: #C0C2C3;
    position: relative;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem;
    gap: 20px;
    width: 15rem;
    margin-left: auto;
    margin-right: auto;
    p{
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
       text-align: start;
        padding-top: 10px;
    }
  


   
}
   

.titulo{
 

            .alcance{
                font-size: 14px;
                background-color: #066AA3;
                color: white;
                border-radius:3px;    
                 padding: 1rem 3.3rem;
                font-weight: 600;
                text-align: start;
                display: flex;
            }
            .valoragregado{
                font-size: 14px;
                background-color: #066AA3;
                color: white;
                border-radius:3px;    
                 padding: 1rem 3.2rem;
                font-weight: 600;
                text-align: start;
                white-space: normal;
                display: flex;
            }
            .infractura{
                font-size: 14px;
                background-color: #066AA3;
                color: white;
                border-radius:3px;    
                 padding: 1rem 4rem;
                font-weight: 600;
                display: flex;
                white-space:normal ;
            }
    
}




@media(width<1000px)
{
    .container{
        width: auto;
        margin: 1rem;
        font-size: 12px;

    }
    .li{
        width:170px;
    }

    img{
        width: 150px;
    }
    .titulo{
 
        font-size: 12px;
        font-weight: 600;
        text-align:start;
        padding: .1rem 3rem;
        color: white;
        background-color: #066AA3;
        border-radius:3px;
            display: flex ;  
      
    
}

.titulo{
 
  padding: 0;
    .alcance{
        font-size: 13px;
        background-color: #066AA3;
        color: white;
        border-radius:3px;    
        padding: 1rem;
        font-weight: 600;
        text-align: start;
        display: flex;
    }
    .valoragregado{
        font-size: 13px;
        background-color: #066AA3;
        color: white;
        border-radius:3px;    
        padding: 1rem;
        
        font-weight: 600;
        text-align: start;
        white-space: normal;
        display: flex;
        white-space: normal;
    }
    .infractura{
        font-size: 13px;
        background-color: #066AA3;
        color: white;
        border-radius:3px;    
        padding: 1rem;
        font-weight: 600;
        display: flex;
        white-space:normal ;
    }

}

}
