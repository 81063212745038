.container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 55rem;


    img {
        border: 5px solid white;
        border-radius: 3px;
        object-fit: contain;
        margin: 0;


    }

    border-radius: 1rem;
    background-color: #D4D6D7;
    padding: 1rem;
    margin-bottom: 1rem;

    a {
        margin-top: 1rem;
        font-size: 13px;
        padding: .5rem;
        background-color: #F37402;
        border-radius: 2px;
        border: none;
        color: white;
        font-weight: 600;
        text-align: center;
        float: right;
        text-decoration: none
    }
a:hover{
        background-color:#ff9a42   ;

       
}
    h2 {
        margin-top: 0;
        color: #066AA3;
        border-bottom: 1px solid gray;
        font-family: Verdana, Arial, Helvetica, sans-serif;

    }
}

.robotica {



    text-align: start;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 3px;
    color: black;
    text-align: start;



    padding-right: .5rem;
    padding-left: .5rem;


}

p {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;    
    font-weight: 500;
    text-align: justify;
    margin: 0;
    margin-top: 10px;

}

.listas {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;




    p {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        margin-top: 10px;


    }






}

.li {


    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #C0C2C3;
    position: relative;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem;
    gap: 20px;
    width: 14rem;

    p {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        text-align: start;
        
            margin-top: 10px;
        
    }
  b{
    color: #066AA3;
    padding-top: 10px;
  };



}

.titulo {


    .sensores {

        font-size: 14px;
        background-color: #066AA3;
        color: white;
        border-radius: 3px;
        padding: 1rem 4rem;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-weight: 600;
        text-align: justify;
        display: flex;
        white-space: normal;

    }

    .fanborg {
        font-size: 14px;
        background-color: #066AA3;
        color: white;
        border-radius: 3px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

        padding: 1rem 4.7rem;
        font-weight: 600;
        white-space: normal;
        display: flex;

    }

    .kitmarket {
        font-size: 14px;
        background-color: #066AA3;
        color: white;
        border-radius: 3px;
        padding: 1rem 3.5rem;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

        font-weight: 600;
        text-align: start;
        display: flex;
    }

}

@media(width<1000px) {
    .container {
        width: auto;
        margin: 1rem;
    }
    .robotica
    {
        width: auto;

    }
    .li{

        width: 10rem;

        
    }
    .titulo {


        .sensores {
    
            font-size: 13px;
   
            padding: 1rem 1.6rem;
  
    
        }
    
        .fanborg {
            font-size: 13px;
       
            padding: 1rem 2.4rem;
   
    
        }
    
        .kitmarket {
            font-size: 13px;
     
            padding: 1rem 1.4rem;
     
        }
    
    }
}