.footer {
  background-color: transparent;
  color: #F17502;
  //   bottom: 0;

//   width: 100%;
  margin-top: auto;
  padding: 6rem;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    background-color: whitesmoke;
    justify-content: center;
    margin: 0;
    flex-wrap: wrap;
  }

  div {
    margin: 10px;
    padding: 10px;
    border-right: 0.5px solid gray;
    justify-content: center;
  }
  span {
    text-align: justify;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  p {
    margin: 0;
    border-bottom: 1px solid gray;
    padding: 10px;
    text-align: center;
    color: #347cc3;
    font-weight: 600;
  }
  label {
    text-align: justify;
    font-size: 13px;
  }
}
