form{
display: flex;
flex-direction: column;
gap: 10px;
width:20rem;
margin-right: 20px;
margin-top: 10px;


input{

    border-radius: 3px;
    border: 1px solid lightgrey;
    font-size: 12px;
    outline: none;
}
button{
    margin-top: 10px;
    margin-bottom: 30px;

    font-size: 13px;
    padding: .6rem;
    // background-color:#347CC3;
    background-color:#F37402   ;

    border: none;
    color: white;
    font-weight: 600;
    width: 5rem;
    border-radius: 2px;
    text-align: center;
margin-left: auto;
  
    }
}
.container
{
    border-radius: 2px;
    padding:1rem ;

    margin-bottom:1rem ;

    h2{
        // color:#f37402 ;
 
            font-family: Verdana, Arial, Helvetica, sans-serif;
        
        border-bottom: 1px solid white;
      }
    width: 40rem;
    color: white;
    font-weight: 600;

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: start;

// background-color:#f0f0f0bc;

    input{

        border-radius: 3px;
        border: 1px solid lightgrey;
        font-size: 13px;
        font-family: 'Poppins', sans-serif;
        outline: none;
        height: 30px;
        gap: 30px;


}
textarea{

    border-radius: 3px;
    border: 3px solid lightgrey;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    outline: none;
    height: 100px;

    
}
p
{
    font-family: 'Poppins', sans-serif;
    
    color: white;
font-size: 14px;
font-weight: 500;
white-space: normal;
}
.form{
    display: flex;
    flex-direction: row;
    
    img{
        border: 5px solid white;
        border-radius: 3px;
        margin: 0;
        height: 15rem;

    }
}

}