.App {
  text-align: center;

}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
// .page{
//   display: flex;
//   flex-direction: column; min-height: 120vh;

// }
// .container{
//   // background-color:#77BEFE  ;
//   background-color: #066AA3;
//   z-index: 1;
//   padding-bottom: 3rem;
// }
// .footer {




// ul {
//   width: 600px;
//   margin-left: auto;
//   margin-right: auto;
//     display: flex;
//     flex-direction: row;
//     list-style: none;
//     background-color: white;
//     justify-content: center;

//   gap: 10px;
//     flex-wrap: wrap;
//   }

//   div {
//     justify-content: center;

//   }
  
//   span {
//     padding: 5px;
//     gap: 10px;

//     margin-bottom: 10px;

//     text-align: justify;
//     font-size: 11px;
//     border-right: 0.5px solid gray;



//     img {
//       display: block;
//       margin-left: auto;
//       margin-right: auto;
//       object-fit: contain;
//     }
//   }
//   p {
//     margin: 0;
//     margin-bottom: 10px;

//     text-align: center;
//     color: #F17502;
//     font-weight: 600;
//     font-size: 14px;

//   }
//   label{
//     font-size: 11px;
    
//     text-align: justify;

//   }

// }
// .imge{

//   border-bottom:  0.5px solid gray;
// }

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 50vh;

}

.App-link {
  color: #61dafb;
}
.page{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container{
  // background-color:#77BEFE  ;
  background-color: #066AA3;
  z-index: 1;

  flex: 1;
}
.footer {


  margin-left: auto;
  margin-right: auto;
    display: flex;
    flex-direction: column;   
     list-style: none;
     // background-color: white;



ul{
  display: flex;
  flex-direction: row;  
gap: 20px;
  li{
  list-style: none;
  border-right: 0.5px solid gray;
  width: 10rem;
  }

  
}
  .li{
   
    display: flex;
    flex-direction: column;  
    justify-content: space-around;

    
    label{
      display: flex;
      flex-direction: row;    
      margin-right: 10px;


      
      
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      object-fit: contain;
      margin-bottom: 10px;
      width: 120px;
      
    }

   
  }

  p {

    margin: 0;

    text-align: center;
    font-weight: 600;
    font-size: 12px;

  }
  span{
    border-top:  0.5px solid gray;

    font-size: 12px;
    margin-top: 10px;
    margin-right: 10px;
    white-space: normal;
    text-align: start;

  }

}

.datos{


  div{
  

    display: flex;
    flex-direction: row;
    background-color: #D4D6D7 ;

          justify-content: center;
          gap:20px;
          padding: 1rem;
    
    p{
      font-size: 12px;

    }
    a{
      color: black;
      list-style: none;
      text-decoration:none

    }
  }

    // padding: 1rem 630px;
}


@media (width<1000px) {



    .li{
      list-style: none;
      border-right: 0.5px solid gray;
      p{
        font-size: 10px;
      }   span{
        font-size: 10px;
      }
      img{
        width: 90px;
      }

      }

  
}