.container{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 55rem;
    gap:20px;

    img{
        border: 5px solid white;
        border-radius: 3px;
        object-fit: contain;
        margin: 0;


    }
    border-radius: 1rem;
    background-color: #D4D6D7;
    padding: 1rem;  
    margin-bottom: 1rem;
    p{
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
    };
  h2{
    
    color:#066AA3;
    border-bottom: 1px solid gray;
    font-family: Verdana, Arial, Helvetica, sans-serif;
}


}
.imagen{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}
.sobrenostros
{

    width: 50rem;
    margin-left: auto;
    margin-right: auto;
    text-align: start;
    display: flex;
    flex-direction: column;
   padding-right: .5rem;
   padding-left: .5rem;
    
    border-radius: 3px;
color: black;
text-align: justify;


p{
    margin-top: 10px;
}
    }


    @media(width<1000px)
{
    .container{
        width: auto;
        margin: 1rem;
    }
    .sobrenostros{
        width: auto;
        
    }
}