.containesNav {
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;

  // padding: 0rem 2rem 0rem 2rem;
  z-index: 1000;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  white-space: normal;
  img{
    margin-left: 200px;

    float: left;
    margin-bottom: 1rem;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 1000;
  padding-top: 1rem;
  padding-bottom: 1rem;

  background-color: white;
p{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}



}
.nav {
  display: flex;
  flex-direction: row;
  background-color: #D4D6D7 ;
  margin-left: auto;
  margin-left: auto;
  padding: 1rem;
  justify-content: center;
  
  label {
    color: black;
    font-weight: 500;
    cursor: pointer;
    font-size: 13px;
  }
  span {
    padding: 1rem;
  }

  .side_bar_item:hover {
    span {
      background-color: white;
      color: #066AA3;
    }
  }

  
}
@media(width>1900px)
{
  .containesNav {
      
    img{
     margin-left: 500px;

    }



   }
}

@media(width<1000px)
{
   .containesNav {
      
     img{
      margin-left: 200px;

     }



    }
    
.logo {

p{
  font-size: 12px;

}



}
    .nav {
      display: flex;

      flex-direction: row;

      padding: 0;

      label{
        font-size: 10px;
justify-content: space-evenly;
font-family: Verdana, Geneva, Tahoma, sans-serif;
display: flex;
      }
    
    }

}