.factory
{


    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 55rem;
    border-radius: 1rem;
    background-color: #D4D6D7;
    padding: 1rem;  
    margin-bottom: 1rem;
    li{
        list-style:none;
        gap: 10px;


    }
    ul{
    gap: 10px;
    }
    label{
        font-size: 16px;
        font-weight: 600;
        

    };
    h2{
        
        color:#066AA3;
        border-bottom: 1px solid gray;
  font-family: Verdana, Arial, Helvetica, sans-serif;

      }
      h4{         
        color:#066AA3;
        margin-top: .6rem;
        margin-bottom: 1rem;

        font-family: Verdana, Arial, Helvetica, sans-serif;

      }

      p{
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        margin-top: 10px;


    };

      
}
.listas{
    display: grid;
  grid-template-columns: 1fr;
  padding-right: .5rem;
  padding-left: .5rem;
        span{
            margin: 10px;
    
        }


}
.lista{
    display: flex;
    flex-direction:row;
    align-items: center;
    
    p{
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        margin-top: 10px;


    };
    text-align: justify;
    label{
        color:#066AA3;
        font-weight: 700;
        font-size: 14px;
        font-family: Verdana, Arial, Helvetica, sans-serif;


    }




}
.numero{
    display: flex;
    flex-direction:row;
    margin: 5px;
   


    span{
        width: 40px;
        height: 40px;
        padding: 10px;
        background-color: #DDDFE0;
        border-radius: 5px;
        // border: .5px solid #181A1C;
        box-shadow:0px 1px 4px 0px rgba(0,0,0,0.6);
        font-family:Arial, Helvetica, sans-serif;
        font-size: 40px;
        font-weight: bold;
        text-align: right;
        


       
    }
    
    border-right:1px solid gray;

}

@media(width<1000px)
{
    .factory{
        width: auto;
        margin: 1rem;
    }

    
}